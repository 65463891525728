import HttpUtil from '../utils/http-util';
import { ROUTE_API } from '../utils/route-util';

const USER_API = {
  createUser: async (data: IUser.ICreateUser) => {
    const res = await HttpUtil.post(ROUTE_API.addUser, data);
    return res.data;
  },
  userList: async () => {
    const res = await HttpUtil.get<IUser.IUserList>(ROUTE_API.userList);
    return res.data;
  },
  delectUser: async (id: string) => {
    const res = await HttpUtil.delete(ROUTE_API.deleteUser.replace(':id', id));
    return res.data;
  },
  updateUser: async (data: IUser.ICreateUser, id: string) => {
    const res = await HttpUtil.put(
      ROUTE_API.updateUser.replace(':id', id),
      data,
    );
    return res.data;
  },
  userDetail: async () => {
    const res = await HttpUtil.get<IUser.IUserDetails>(ROUTE_API.getUserDetail);
    return res.data.data;
  },
};
export { USER_API };
